import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, IconButton, Stack, Alert } from '@mui/material';

import { Dispatch, SetStateAction, useState, useContext } from 'react';
import { IPlan, NewKey } from '../../functions/Fetchings';
import { DialogContext } from '../../contexts/DialogContext';
import { UserContext } from '../../contexts/UserContext';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { accessKeyChange } from '../../functions/Misc';

export interface INewKeyModal {
    plan: IPlan | null;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const NewKeyModal = ({ plan, open, setOpen }: INewKeyModal) => {
    const { setLoading } = useContext(DialogContext);
    const { user } = useContext(UserContext);

    const [key, setKey] = useState<string | null>(null);
    const newKey = async () => {
        setLoading(true);

        try {
            console.log(plan);
            if (!plan) return;

            const json = await NewKey(user, plan);
            setKey(json.key);
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    }

    return (
        <Dialog open={open} maxWidth="lg" fullWidth>
            <DialogTitle>New Access Key</DialogTitle>
            <Box position="absolute" top={10} right={10}>
                <IconButton onClick={() => {
                    setOpen(false);
                }}>
                    <FontAwesomeIcon icon={faXmark} />
                </IconButton>
            </Box>
            <DialogContent>
                <Stack direction={"row"} spacing={2.5} display={"flex"} alignItems={"center"} justifyContent="center">
                    <Alert sx={{ width: "70%" }} color={key ? "info" : "warning"}>
                        {key ? key : "Keys may only be displayed once."}
                    </Alert>
                    <Button
                        size="medium"
                        type="submit"
                        sx={{ width: "100%", maxWidth: 250 }}
                        variant="contained"
                        disabled={!plan || !accessKeyChange(plan)}
                        onClick={() => newKey()}
                    >
                        Generate New Key
                    </Button>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
                <Button color="primary" variant="contained" onClick={() => {
                    setOpen(false);
                }}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default NewKeyModal;