import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, IconButton, } from '@mui/material';
import { Dispatch, ReactElement, SetStateAction } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export interface IConfirmModalMessage {
    title: string;
    content: ReactElement;
    cancel?: string;
    confirm?: string;
}
export interface IConfirmModal {
    message: IConfirmModalMessage
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    callback: (confirmed: boolean) => void;
}

const ConfirmModal = ({ message, open, setOpen, callback }: IConfirmModal) => {
    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogTitle>{message.title}</DialogTitle>
            <Box position="absolute" top={10} right={10}>
                <IconButton onClick={() => {
                    setOpen(false);
                    callback(false);
                }}>
                    <FontAwesomeIcon icon={faXmark} />
                </IconButton>
            </Box>
            <DialogContent>
                {message.content}
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
                <Button color="secondary" variant="contained" onClick={() => {
                    setOpen(false);
                    callback(false);
                }}>
                    {message.cancel || "Cancel"}
                </Button>
                <Button color="primary" variant="contained" onClick={() => {
                    setOpen(false);
                    callback(true);
                }}>
                    {message.confirm || "Confirm"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmModal;