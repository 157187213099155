import { InternalRoute } from "../../react-app-env";
import { Box, ButtonBase } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';

import * as CookieConsent from "vanilla-cookieconsent";

import Grid from '@mui/material/Grid2'; // Grid version 2
import React from "react";

export interface ISideBarLink {
    route: InternalRoute;
    setDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideBarLink = ({ route, setDrawer }: ISideBarLink) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Box component="li">
            <ButtonBase
                onClick={() => {
                    setDrawer(false)

                    if (route.type === "cookie") CookieConsent.showPreferences();
                    else navigate(route.id);
                }}
                sx={{
                    alignItems: 'center',
                    borderRadius: 1,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    pl: '16px',
                    pr: '16px',
                    py: '6px',
                    textAlign: 'left',
                    width: '100%',
                    ...(location.pathname === route.id && {
                        backgroundColor: 'rgba(255, 255, 255, 0.04)'
                    }),
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.04)'
                    }
                }}
            >
                <Grid container width={"100%"}>
                    <Grid size={2} display="flex" alignItems="center" justifyContent="center" sx={{
                        ...(location.pathname === route.id && {
                            color: 'primary.main'
                        })
                    }}>
                        {route.icon}
                    </Grid>
                    <Grid size={10} display="flex" alignItems="center" sx={{
                        fontFamily: (theme) => theme.typography.fontFamily,
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: '24px',
                        whiteSpace: 'nowrap',
                        ...(location.pathname === route.id && {
                            color: 'common.white'
                        })
                    }}>
                        {route.name}
                    </Grid>
                </Grid>
            </ButtonBase>
        </Box>
    );
}

export default SideBarLink;