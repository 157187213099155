import { Backdrop, CircularProgress } from "@mui/material";

export interface ILoaderView {
    progress: boolean;
}

const LoaderView = ({ progress }: ILoaderView) => {
    return (
        <Backdrop open={true} sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1
        }}>
            {progress && <CircularProgress />}
        </Backdrop>
    );
}

export default LoaderView;