import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { createContext, useState, useEffect } from "react";
import ConfirmModal, { IConfirmModalMessage } from "../components/modals/ConfirmModal";
import ChangePasswordModal from "../components/modals/ChangePasswordModal";
import { IPlan } from "../functions/Fetchings";
import NewKeyModal from "../components/modals/NewKeyModal";
import { SIDEBAR_WIDTH } from "../Constants";
import { ICookieSettings } from "../react-app-env";
import EnableCookiesModal from "../components/modals/EnableCookiesModal";

export interface IDialogContext {
    setLoading: (loading: boolean) => void;
    showConfirm: (message: IConfirmModalMessage, callback: (confirmed: boolean) => void) => void;
    showChangePassword: (callback: () => void) => void;
    showNewKey: (plan: IPlan) => void
}
export interface IDialogProvider {
    cookies: ICookieSettings;
    setCookies: React.Dispatch<React.SetStateAction<ICookieSettings>>;
    children: React.ReactNode;
}

/**
 * IMPORTANT
 * USE () => () => {} here because of state initialization
 * 
 * https://stackoverflow.com/a/55621679/5597879
 */
const DialogContext = createContext<IDialogContext>({
    setLoading: () => () => { },
    showConfirm: () => () => { },
    showChangePassword: () => () => { },
    showNewKey: () => () => { },
});

const DialogProvider = ({ cookies, setCookies, children }: IDialogProvider) => {
    const [loadingState, setLoadingState] = useState(false);

    const [enableCookiesModal, setEnableCookiesModal] = useState<boolean>(false);
    useEffect(() => {
        console.log(cookies)

        if (cookies.functionality && cookies.onChange) {
            /*switch (cookies.onChange.type) {
                case "request-plan":
                    console.log("Enabled Cookies for Plan Request");
                    setPlanRequestModal(true);
                    break;
            }*/
            setCookies((cookies) => ({ ...cookies, onChange: undefined }));
        }
    }, [cookies.functionality]);

    const [confirmCancelModal, setConfirmCancelModal] = useState<boolean>(false);
    const [confirmCancelModalMessage, setConfirmCancelModalMessage] = useState<IConfirmModalMessage>({ title: "", content: <Typography /> });
    const [confirmCancelModalCallback, setConfirmCancelCallback] = useState<(confirmed: boolean) => void>(() => () => { });

    const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
    const [showPasswordModalCallback, setShowPasswordModalCallback] = useState<() => void>(() => () => { });

    const [showNewKeyModal, setShowNewKeyModal] = useState<boolean>(false);
    const [showNewKeyPlan, setShowNewKeyPlan] = useState<IPlan | null>(null);

    const setLoading = (loading: boolean) => {
        setLoadingState(loading);
    }
    const showConfirm = (message: IConfirmModalMessage, callback: (confirmed: boolean) => void) => {
        setConfirmCancelModalMessage(message);
        setConfirmCancelCallback(() => callback);
        setConfirmCancelModal(true);
    }

    const showChangePassword = (callback: () => void) => {
        setShowPasswordModalCallback(() => callback);
        setShowPasswordModal(true);
    }

    const showNewKey = (plan: IPlan) => {
        setShowNewKeyPlan(plan);
        setShowNewKeyModal(true);
    }

    return (
        <DialogContext.Provider value={{ setLoading, showConfirm, showChangePassword, showNewKey }}>
            <Backdrop open={loadingState} sx={{
                left: {
                    xs: 0,
                    lg: SIDEBAR_WIDTH
                },
                color: '#fff',
                zIndex: (theme) => Math.max.apply(Math, Object.values(theme.zIndex)) + 1
            }}>
                <CircularProgress />
            </Backdrop>

            <EnableCookiesModal
                open={enableCookiesModal}
                setOpen={setEnableCookiesModal}
            />

            <ConfirmModal
                open={confirmCancelModal}
                setOpen={setConfirmCancelModal}
                message={confirmCancelModalMessage}
                callback={confirmCancelModalCallback}
            />

            <ChangePasswordModal
                open={showPasswordModal}
                setOpen={setShowPasswordModal}
                callback={showPasswordModalCallback}
            />

            <NewKeyModal
                open={showNewKeyModal}
                setOpen={setShowNewKeyModal}
                plan={showNewKeyPlan}
            />

            {
            /*Add everything that depends on func cookies to be loaded here*/
            /*
            if (!cookies.functionality) {
                setCookies((cookies) => ({
                    ...cookies,
                    onChange: { type: "request-plan" }
                }));
                setEnableCookiesModal(true);
            } else {
                setPlanRequestModal(true);
            }
            */
            }
            {cookies.functionality &&
                <></>
            }

            {children}
        </DialogContext.Provider>
    )
}

export { DialogContext, DialogProvider }