import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, IconButton, Stack, FormControl, OutlinedInput, InputLabel, InputAdornment, Alert } from '@mui/material';

import { Dispatch, SetStateAction, useState, useEffect, useContext } from 'react';

import { PasswordValidator } from '../../functions/Misc';
import { DialogContext } from '../../contexts/DialogContext';
import { ChangePassword } from '../../functions/Fetchings';
import { UserContext } from '../../contexts/UserContext';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export interface IChangePasswordModal {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    callback: () => void;
}

const ChangePasswordModal = ({ open, setOpen, callback }: IChangePasswordModal) => {
    const { setLoading } = useContext(DialogContext);
    const { user } = useContext(UserContext);

    const [newPassword, setNewPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [oldPassword, setOldPassword] = useState('');

    useEffect(() => {
        if (newPassword.length > 0) {
            const validation = PasswordValidator.validate(newPassword, { details: true });
            if (validation.length > 0) setPasswordError(validation[0].message.replace("string", "password"));
            else setPasswordError('');
        } else {
            setPasswordError('');
        }
    }, [newPassword]);

    const changePassword = async () => {
        if (!user) return;

        setLoading(true);

        try {
            await ChangePassword(user, oldPassword, newPassword);
            setOpen(false);
            callback();
        } catch (err) {
            if (err instanceof Error) setPasswordError(err.message);
        }

        setLoading(false);
    }

    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogTitle>Change Password</DialogTitle>
            <Box position="absolute" top={10} right={10}>
                <IconButton onClick={() => {
                    setOpen(false);
                }}>
                    <FontAwesomeIcon icon={faXmark} />
                </IconButton>
            </Box>
            <DialogContent>
                <Stack spacing={2.5}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel size="small" htmlFor="old-password">Old Password</InputLabel>
                        <OutlinedInput
                            id="old-password"
                            type='password'
                            size="small"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            label="Old Password"
                        />
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel size="small" htmlFor="new-password">New Password</InputLabel>
                        <OutlinedInput
                            id="new-password"
                            size="small"
                            type={showPassword ? 'text' : 'password'}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword((show) => !show)}
                                        onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                                        edge="end"
                                    >
                                        {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="New Password"
                        />
                    </FormControl>

                    {passwordError && <Alert
                        severity="error"
                        sx={{ mt: 3 }}
                    >
                        <div>
                            Error: {passwordError}
                        </div>
                    </Alert>}
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
                <Button color="secondary" variant="contained" onClick={() => {
                    setOpen(false);
                }}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!user || oldPassword.length < 8 || newPassword.length < 8 || !PasswordValidator.validate(newPassword)}
                    onClick={() => {
                        changePassword();
                    }}>
                    Change
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ChangePasswordModal;