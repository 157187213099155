import { createContext, useCallback, useEffect, useState } from "react";
import { ICookieSettings, IUser } from "../react-app-env";
import { VerifyUser } from "../functions/Fetchings";
import moment from "moment";

const DEFAULT_USER_ERRORS = [
    "Missing refresh token", 
    "Session expired", 
    "Token mismatch", 
    "Invalid refresh token",
    "NetworkError when attempting to fetch resource.",
];

export interface IUserProvider {
    user: IUser;
    setUser: (user: IUser) => void;
    verifyUser: () => Promise<void>;
}
export interface IUserProviderProps {
    cookies: ICookieSettings,
    children: React.ReactNode;
}

export const DEFAULT_USER: IUser = {
    performedRequest: false,
    flags: [],
    hadTrial: true,
    token: null,
    plans: [{
        _id: "trial",
        startOn: moment().toDate(),
        endOn: moment().add(1, "year").toDate(),
        types: {
            trial: true,
            small: 0,
            medium: 0,
            large: 0
        },
        secret: {
            lastKeyChange: undefined
        },
        hubspotID: "plan-123",
        currentPeriod: {
            periodStart: moment().unix(),
            periodEnd: moment().add(1, "month").unix()
        },
        status: "valid",
        totalQuota: 1000,
    }],
    selectedPlan: "trial",
}

const UserContext = createContext<IUserProvider>({
    user: DEFAULT_USER,
    setUser: () => { },
    verifyUser: async () => { },
});

const UserProvider = ({ cookies, children }: IUserProviderProps) => {
    const [user, setUserState] = useState<IUser>(DEFAULT_USER);

    const setUser = (user: IUser) => {
        setUserState(user);

        if (cookies.functionality) {
            if (user.selectedPlan && user.selectedPlan !== DEFAULT_USER.selectedPlan) {
                localStorage.setItem("selectedPlan", user.selectedPlan);
            }
        }
    }

    const verifyUser = useCallback(async () => {
        try {
            const json = await VerifyUser();
            let selectedPlan: string | "trial" | undefined = undefined;
            if (user.selectedPlan === "trial" || !user.plans.some(e => e._id === user.selectedPlan)) {
                if (json.plans.length > 0) selectedPlan = json.plans[0]._id
            } else {
                selectedPlan = user.selectedPlan
            }

            setUserState(user => ({
                ...user,
                ...json,
                selectedPlan: selectedPlan,
                performedRequest: true
            }));
        } catch (err) {
            console.error(err);
            if (err instanceof Error) {
                if (DEFAULT_USER_ERRORS.includes(err.message)) {
                    setUserState({ ...DEFAULT_USER, performedRequest: true });
                }
            }
        }

        // Auto refresh every 5 minutes
        setTimeout(verifyUser, 5 * 60 * 1000)
    }, [setUserState]);
    useEffect(() => {
        verifyUser()
    }, [verifyUser]);

    useEffect(() => {
        if (cookies.functionality) {
            const storedPlan = localStorage.getItem("selectedPlan");
            if (storedPlan !== null && storedPlan !== user.selectedPlan && user.plans.some(e => e._id === storedPlan)) {
                setUserState(user => ({
                    ...user,
                    selectedPlan: storedPlan,
                }));
            }
        }
    }, [cookies.functionality, user.plans]);

    return (
        <UserContext.Provider value={{ user, setUser, verifyUser }}>
            {children}
        </UserContext.Provider>
    )
}

export { UserContext, UserProvider }