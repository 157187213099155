import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, IconButton, Stack, Typography } from '@mui/material';

import { Dispatch, SetStateAction } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import * as CookieConsent from "vanilla-cookieconsent";

export interface IEnableCookiesModal {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const EnableCookiesModal = ({ open, setOpen }: IEnableCookiesModal) => {
    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogTitle>Enable Functional Cookies</DialogTitle>
            <Box position="absolute" top={10} right={10}>
                <IconButton onClick={() => {
                    setOpen(false);
                }}>
                    <FontAwesomeIcon icon={faXmark} />
                </IconButton>
            </Box>
            <DialogContent>
                <Stack spacing={2.5}>
                    <Typography variant='body1'>
                        The action you just requested requires you to enable Functional Cookies.
                    </Typography>
                    <Button
                        size="medium"
                        type="submit"
                        sx={{ width: "100%" }}
                        variant="contained"
                        onClick={() => {
                            CookieConsent.acceptCategory("functionality");
                            setOpen(false);
                        }}
                    >
                        Accept & Enable Functional Cookies
                    </Button>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
                <Button color="primary" variant="contained" onClick={() => {
                    setOpen(false);
                }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EnableCookiesModal;