import { Moment } from "moment";
import { BACKEND_URL } from "../Constants";

/**
 * General
 */
export interface IPlan {
    hubspotID: string;
    startOn: Date;
    endOn: Date;
    status: "valid" | "invalid";
    types: {
        trial: boolean;
        small: number;
        medium: number;
        large: number;
    };
    secret: {
        lastKeyChange?: Date;
    };
    _id: string;
    createdAt?: Date;
    updatedAt?: Date;
    totalQuota: number;
    currentPeriod: { periodStart: number | undefined, periodEnd: number | undefined };
}

/**
 * App
 */
export interface IVerifyUser {
    token: string | null;
    plans: IPlan[];
    flags: ("admin-readonly" | "admin-ip")[];
    hadTrial: boolean;
}
export const VerifyUser = async (): Promise<IVerifyUser> => {
    const response = await fetch(`${BACKEND_URL}/contact/refresh-token`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) throw new Error(await response.text());

    const json = await response.json();
    console.log(json);
    return json;
}

/**
 * Index
 */
export interface IRawDashboardPlanData {
    quota: number;
    totalQuota: number;
    requests: { value: number, change: number };
    statistics: {
        quotaByYear: {
            _id: {
                month: number;
            },
            quota: number;
        }[];
        typeByYear: {
            _id: {
                type: "workitem" | "chatbot" | "linked-item";
            },
            quota: number;
        }[];
        quotaByMonth: {
            _id: {
                month: number;
                day: number;
            },
            quota: number;
        }[];
        typeByMonth: {
            _id: {
                type: "workitem" | "chatbot" | "linked-item";
            },
            quota: number;
        }[];
    }
}
export interface IDashboard {
    plans: {
        plan: string;
        data: IRawDashboardPlanData;
        timestamp: Moment;
    }[]
}
export const Dashboard = async (user: IVerifyUser, planID: string): Promise<IRawDashboardPlanData> => {
    const response = await fetch(`${BACKEND_URL}/contact/dashboard`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
        },
        credentials: "include",
        body: JSON.stringify({
            planID: planID,
        })
    });

    if (!response.ok) throw new Error(await response.text());

    const json = await response.json();
    console.log(json);

    return json;
}

/**
 * Sign Up
 */
export const SignUpCode = async (email: string, terms: boolean) => {
    const response = await fetch(`${BACKEND_URL}/contact/request-email-code`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify({
            email: email,
            terms: terms
        })
    });

    if (!response.ok) throw new Error(await response.text());

    const json = await response.json();
    console.log(json);
}
export const SignUpConfirm = async (email: string, code: string, password: string) => {
    const response = await fetch(`${BACKEND_URL}/contact/confirm-email-code`, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            email: email,
            code: code,
            password: password
        })
    });

    if (!response.ok) throw new Error(await response.text());

    const json = await response.json();
    console.log(json);
}

/**
 * Sign In
 */
export const SignIn = async (email: string, password: string): Promise<IVerifyUser> => {
    const response = await fetch(`${BACKEND_URL}/contact/login`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify({
            email: email,
            password: password
        })
    });

    if (!response.ok) throw new Error(await response.text());

    const json = await response.json();
    console.log(json);

    return json;
}

/**
 * Logout
 */
export const Logout = async (user: IVerifyUser) => {
    const response = await fetch(`${BACKEND_URL}/contact/logout`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
        },
        credentials: "include",
    });

    if (!response.ok) throw new Error(await response.text());

    const json = await response.json();
    console.log(json);

    return json;
}

/**
 * Forgot Password
 */
export const ForgotPasswordCode = async (email: string) => {
    const response = await fetch(`${BACKEND_URL}/contact/forgot-password-code`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify({
            email: email
        })
    });

    if (!response.ok) throw new Error(await response.text());

    const json = await response.json();
    console.log(json);
}
export const ForgotPasswordConfirm = async (email: string, code: string, password: string) => {
    const response = await fetch(`${BACKEND_URL}/contact/confirm-forgot-password-code`, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            email: email,
            code: code,
            password: password
        })
    });

    if (!response.ok) throw new Error(await response.text());

    const json = await response.json();
    console.log(json);
}

/**
 * Account
 */
export interface IKey {
    key: string;
}
export const NewKey = async (user: IVerifyUser, plan: IPlan): Promise<IKey> => {
    const response = await fetch(`${BACKEND_URL}/contact/request-secret`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
        },
        credentials: "include",
        body: JSON.stringify({
            id: plan._id,
        }),
    });

    if (!response.ok) throw new Error(await response.text());

    const json = await response.json();
    console.log(json);

    return { key: json.key }
}

export const ChangePassword = async (user: IVerifyUser, oldPassword: string, newPassword: string) => {
    const response = await fetch(`${BACKEND_URL}/contact/change-password`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
            oldPassword: oldPassword,
            newPassword: newPassword,
        }),
        credentials: "include",
    });

    if (!response.ok) throw new Error(await response.text());

    const json = await response.json();
    console.log(json);
}

export const DeleteAccount = async (user: IVerifyUser) => {
    const response = await fetch(`${BACKEND_URL}/contact/delete-account`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
        },
        credentials: "include",
    });

    if (!response.ok) throw new Error(await response.text());

    const json = await response.json();
    console.log(json);
}

export interface ILog {
    _id: string;
    createdAt: Date;
    type: "workitem" | "chatbot" | "linked-item" | "doc-analysis" | undefined;
    value: number;
    user: string;
    clientIP: string | undefined;
    ipBlocked: boolean;
    fraudLevel: number;
    details: any;
}
export interface IGetLog {
    log: ILog[];
}
export const GetLog = async (user: IVerifyUser, plan: IPlan, pageSize: number, page: number, sort: "createdAt" | "type" | "value", sortOrder: -1 | 1): Promise<IGetLog> => {
    const response = await fetch(`${BACKEND_URL}/contact/access-log`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
            planID: plan._id,
            pageSize: pageSize,
            page: page,
            sort: sort,
            sortOrder: sortOrder
        }),
        credentials: "include",
    });

    if (!response.ok) throw new Error(await response.text());

    const json = await response.json();
    console.log(json);

    return json;
}

/**
 * DOWNLOAD
 */
export const DownloadFile = async (user: IVerifyUser, file: string, file_name: string) => {
    const response = await fetch(`${BACKEND_URL}/downloads/${file}`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
        },
        credentials: "include",
    });

    if (!response.ok) throw new Error(await response.text());
    
    const blob = await response.blob();
    const href = URL.createObjectURL(blob);
    const a = Object.assign(document.createElement("a"), {
        href,
        style: "display:none",
        download: file_name,
    });
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(href);
    a.remove();
}

/**
 * ADMIN - Contacts
 */
export interface IAdminContact {
    _id: string;
    auth: {
        email: string;
        lastLogin: Date;
    }
    createdAt: Date;
    plans: IPlan[];
}
export interface IAllContacts {
    contacts: IAdminContact[];
}
export interface ISingleContact {
    contact: IAdminContact | null;
}
export const AllContacts = async (user: IVerifyUser, contact?: string): Promise<IAllContacts> => {
    const response = await fetch(`${BACKEND_URL}/admin/contacts`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
            contact,
        }),
        credentials: "include",
    });

    if (!response.ok) throw new Error(await response.text());

    const json = await response.json();
    console.log(json);

    return json;
}

export interface IAllContactEmails {
    emails: IAdminContact[];
}
export const AllContactEmails = async (user: IVerifyUser): Promise<IAllContactEmails> => {
    const response = await fetch(`${BACKEND_URL}/admin/all-contacts-emails`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
        },
        credentials: "include",
    });

    if (!response.ok) throw new Error(await response.text());

    const json = await response.json();
    console.log(json);

    return json;
}

export interface IAdminLogs {
    logs: string | null;
}
export const AdminLogs = async (user: IVerifyUser): Promise<IAdminLogs> => {
    const response = await fetch(`${BACKEND_URL}/admin/logs`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
        },
        credentials: "include",
    });

    if (!response.ok) throw new Error(await response.text());

    const json = await response.json();
    console.log(json);

    return json;
}

export interface IFraud {
    frauds: {
        _id: string;
        maxFraudLevel: number;
        plan: IPlan;
        contact: IAdminContact
    }[];
}

export const GetFraud = async (user: IVerifyUser): Promise<IFraud> => {
    const response = await fetch(`${BACKEND_URL}/admin/fraud-radar`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
        },
        credentials: "include",
    });

    if (!response.ok) throw new Error(await response.text());

    const json = await response.json();
    console.log(json);

    return json;
}

export const HandleIP = async (user: IVerifyUser, clientIP: string, action: "restrict" | "unrestrict") => {
    const response = await fetch(`${BACKEND_URL}/admin/handle-ip`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
            clientIP,
            action,
        }),
        credentials: "include",
    });

    if (!response.ok) throw new Error(await response.text());

    const json = await response.json();
    console.log(json);

    return json;
}

export const HandleMail = async (user: IVerifyUser, contact: string, action: "plan-mail" | "welcome-mail") => {
    const response = await fetch(`${BACKEND_URL}/admin/handle-mail`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
            contact,
            action,
        }),
        credentials: "include",
    });

    if (!response.ok) throw new Error(await response.text());

    const json = await response.json();
    console.log(json);

    return json;
}